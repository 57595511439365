<template>
  <div id="app">
    <h1>Calculadora de Viagem para Caminhoneiros</h1>
    <div class="form-container">
      <form @submit.prevent="calcularViagem">
        <div class="field-container">
          <label for="tempoCarregamento">Tempo de Carregamento (horas):</label>
          <input type="number" id="tempoCarregamento" v-model.number="tempoCarregamento">
        </div>
        <div class="field-container">
          <label for="tempoDescarregamento">Tempo de Descarregamento (horas):</label>
          <input type="number" id="tempoDescarregamento" v-model.number="tempoDescarregamento">
        </div>
        <div class="field-container">
          <label for="kmPercorrer">Km a Percorrer:</label>
          <input type="number" id="kmPercorrer" v-model.number="kmPercorrer">
        </div>
        <div class="field-container">
          <label for="dataPartida">Data e Horário de Partida:</label>
          <input type="datetime-local" id="dataPartida" v-model="dataPartida">
        </div>
        <button type="submit">Calcular</button>
      </form>
    </div>
    <Timeline :viagem="viagem" />
  </div>
</template>

<script>
import Timeline from './components/Timeline.vue';


export default {
  components: {
    Timeline
  },
  data() {
    return {
      tempoCarregamento: 0,
      tempoDescarregamento: 0,
      dataPartida: '',
      kmPercorrer: 0,
      viagem: this.viagem
    };
  },
  methods: {
    calcularViagem() {
  // Define as variáveis iniciais com base nos dados de entrada.
  const partida = new Date(this.dataPartida);
  const carregamento = this.tempoCarregamento;
  const descarregamento = this.tempoDescarregamento;
  const distancia = this.kmPercorrer;
  const velocidadeMedia = 50; // km/h
  const maxHorasDirecao = 8; // Máximo de horas de direção contínua.
  const horaInicioRestricao = 22; // Hora em que inicia a restrição de direção.
  const horaFimRestricao = 5; // Hora em que termina a restrição de direção.

  let horaAtual = new Date(partida); // Data e hora de início da viagem.
  let timeline = []; // Linha do tempo com os segmentos da viagem.

  // Adiciona o segmento de carregamento.
  timeline.push({
    tipo: 'Carregamento',
    inicio: new Date(horaAtual),
    fim: new Date(horaAtual.setHours(horaAtual.getHours() + carregamento))
  });

  let tempoViagemRestante = distancia / velocidadeMedia; // Tempo de viagem restante em horas.

  // Processo de cálculo da linha do tempo.
  while (tempoViagemRestante > 0) {
    if (horaAtual.getHours() >= horaInicioRestricao) {
      // Adiciona o segmento de não direção até as 5h da manhã do dia seguinte.
      let naoDirecaoInicio = new Date(horaAtual);
      horaAtual.setHours(horaFimRestricao);
      horaAtual.setDate(horaAtual.getDate() + 1);
      timeline.push({
        tipo: 'Não Direção',
        inicio: naoDirecaoInicio,
        fim: new Date(horaAtual)
      });
    } else {
      // Calcula o tempo até a próxima pausa ou até o horário de restrição.
      let duracaoDirecao = Math.min(tempoViagemRestante, maxHorasDirecao);
      if (horaAtual.getHours() + duracaoDirecao >= horaInicioRestricao) {
        // Ajusta a duração da direção para parar antes das 22h.
        duracaoDirecao = horaInicioRestricao - horaAtual.getHours() - 1/60; // Subtrai um minuto para parar às 21:59.
      }
      let inicioDirecao = new Date(horaAtual);
      horaAtual.setHours(horaAtual.getHours() + duracaoDirecao);
      let fimDirecao = new Date(horaAtual);

      timeline.push({
        tipo: 'Direção',
        inicio: inicioDirecao,
        fim: fimDirecao
      });

      tempoViagemRestante -= duracaoDirecao;

      // Adiciona uma pausa se ainda houver tempo de viagem restante.
      if (tempoViagemRestante > 0 && horaAtual.getHours() < horaInicioRestricao) {
        let inicioPausa = new Date(horaAtual);
        horaAtual.setMinutes(horaAtual.getMinutes() + 30); // Pausa de 30 minutos.
        timeline.push({
          tipo: 'Pausa',
          inicio: inicioPausa,
          fim: new Date(horaAtual)
        });
      }
    }
  }

  // Adiciona o segmento de descarregamento após o término da direção.
  let inicioDescarregamento = new Date(horaAtual);
  timeline.push({
    tipo: 'Descarregamento',
    inicio: inicioDescarregamento,
    fim: new Date(horaAtual.setHours(horaAtual.getHours() + descarregamento))
  });

  // Atribui a linha do tempo calculada à variável de estado `viagem`.
  this.viagem = timeline;
},
}

};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinha o conteúdo ao topo */
  align-items: center;
  min-height: 100vh;
  padding-top: 40px; /* Adiciona um espaço no topo */
  background-color: #f5f5f5;
}

h1 {
  margin-bottom: 20px;
}

.form-container {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.field-container {
  margin-bottom: 15px;
}

button {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
</style>
