<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="timeline-container">
    <!-- Itera sobre cada segmento da viagem e exibe na timeline -->
    <div class="timeline-segment" v-for="(segment, index) in viagem" :key="index">
      <div class="segment-info">
        <div class="segment-type">{{ segment.tipo }}</div>
        <div class="segment-time">
          <div class="time">{{ formatHour(segment.inicio) }}</div>
          <div class="date">{{ formatDate(segment.inicio) }}</div>
        </div>
        <span v-if="segment.fim">-</span>
        <div class="segment-time" v-if="segment.fim">
          <div class="time">{{ formatHour(segment.fim) }}</div>
          <div class="date">{{ formatDate(segment.fim) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    viagem: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatHour(isoString) {
      const date = new Date(isoString);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    },
    formatDate(isoString) {
      const date = new Date(isoString);
      return date.toLocaleDateString();
    },
  },
};
</script>

<style>
/* Seus estilos existentes... */
.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa; /* Cor de fundo do Bootstrap */
}

.timeline-segment {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 15px 20px;
  margin-bottom: 10px;
  width: 80%; /* A largura pode ser ajustada conforme necessário */
  max-width: 500px; /* Um max-width para manter a legibilidade em telas maiores */
}

.segment-info {
  text-align: center;
}

.segment-type {
  color: #007bff; /* Cor primária do Bootstrap */
  font-weight: 500; /* Peso da fonte semelhante ao Material-UI */
  margin-bottom: 5px;
}

.segment-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px; /* Tamanho de fonte comum para detalhes */
}

.time {
  color: #495057; /* Cor de texto do Bootstrap */
  font-weight: bold;
}

.date {
  color: #6c757d; /* Cor de texto secundário do Bootstrap */
}
</style>